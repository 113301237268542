import http from '@/common/http';
import constants from '@/config/constants';
import { isEmpty } from '@/common/validators';
import { i18n } from '@/plugin/i18n';

const initialData = {
  user_id: null,
  nickname: null,
  age: null,
  birth_year: null,
  gender: null,
  email: null,
  residence: null,
  is_receiving_emails: false,
  job: null,
  job_type: null,
  deleted: false,
  deleted_at: null,
  permission: null,
  tenant_id: null,
  tenant_code: null,
  age_range: null,
  tags: null
};
const initialDataExcludeId = {
  nickname: initialData.nickname,
  age: initialData.age,
  birth_year: initialData.birth_year,
  gender: initialData.gender,
  email: initialData.email,
  residence: initialData.residence,
  is_receiving_emails: initialData.is_receiving_emails,
  job: initialData.job,
  job_type: initialData.job_type,
  deleted: initialData.deleted,
  deleted_at: initialData.deleted_at,
  tenant_id: initialData.tenant_id,
  tenant_code: initialData.tenant_code,
  age_range: initialData.age_range,
  tags: initialData.tags
};

export default {
  namespaced: true,
  state: {
    user_id: initialData.user_id,
    nickname: initialData.nickname,
    age: initialData.age,
    birth_year: initialData.birth_year,
    gender: initialData.gender,
    email: initialData.email,
    residence: initialData.residence,
    is_receiving_emails: initialData.is_receiving_emails,
    job: initialData.job,
    job_type: initialData.job_type,
    deleted: initialData.deleted,
    deleted_at: initialData.deleted_at,
    permission: initialData.permission,
    tenant_id: initialData.tenant_id,
    tenant_code: initialData.tenant_code,
    age_range: initialData.age_range,
    tags: initialData.tags
  },
  getters: {
    user_id(state) {
      return state.user_id;
    },
    nickname(state) {
      return state.nickname;
    },
    age(state) {
      return state.age;
    },
    birth_year(state) {
      return state.birth_year;
    },
    gender(state) {
      return state.gender;
    },
    email(state) {
      return state.email;
    },
    residence(state) {
      return state.residence;
    },
    is_receiving_emails(state) {
      return state.is_receiving_emails;
    },
    job(state) {
      return state.job;
    },
    job_type(state) {
      return state.job_type;
    },
    deleted(state) {
      return state.deleted;
    },
    deleted_at(state) {
      return state.deleted_at;
    },
    permission: state => (key, defValue) => {
      if (state.permission != null && key in state.permission) {
        return state.permission[key];
      } else {
        return defValue;
      }
    },
    tenant_id(state) {
      return state.tenant_id;
    },
    tenant_code(state) {
      return state.tenant_code;
    },
    age_range(state) {
      return state.age_range;
    },
    tags(state) {
      return state.tags;
    }
  },
  actions: {
    /**
     * ユーザ取得処理
     * @param reqData リクエストデータ
     */
    async getRequest({ dispatch, commit }) {
      return new Promise((resolve, reject) => {
        http
          .post(constants.API_USER_REFERENCE, {})
          .then(response => {
            if (response) {
              // メール受信設定をbool値から文字列に置き換える
              if (response.data.isReceivingEmails == true) {
                response.data.isReceivingEmails = i18n.global.t('userInfoCommon.form.isReceivingEmails.options.receive.true');
              } else {
                response.data.isReceivingEmails = i18n.global.t('userInfoCommon.form.isReceivingEmails.options.receive.false');
              }
              commit('setUserInfo', response.data);
              dispatch('tag/setTagKeyValues', response.data.tag_key_values, { root: true });
            }
            // index.jsで使用する各フラグを返す
            resolve({
              // 利用規約再同意フラグ
              usersReagree: response.data.usersReagree,
              // 個人情報の取扱い再同意フラグ
              personalReagree: response.data.personalReagree,
              // 未登録のプロフィール拡張項目登録フラグ
              unregisteredTenantForm: response.data.unregisteredTenantForm ? response.data.unregisteredTenantForm : false
            });
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    /**
     * 規約再同意情報取得処理
     * @param reqData リクエストデータ
     */
    async getTermsReagree() {
      return new Promise((resolve, reject) => {
        http
          .post(constants.API_USER_REFERENCE, {})
          .then(response => {
            // 規約再同意情報を返却
            resolve({
              // 利用規約再同意フラグ
              usersReagree: response.data.usersReagree,
              // 個人情報の取扱い再同意フラグ
              personalReagree: response.data.personalReagree
            });
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    /**
     * ノーログインユーザ取得処理
     * @param reqData リクエストデータ
     */
    async getRequestByNoLoginUser({ dispatch, commit }, params) {
      return new Promise((resolve, reject) => {
        const param = {
          NoLoginAccess: true
        };
        let url = constants.API_NOLOGINUSER_URI + '/' + params.u_id;

        if (!isEmpty(params.tags)) {
          // URLにタグ情報を追加する
          url = url + '?tags=' + params.tags;
        }

        http
          .get(url, param)
          .then(response => {
            if (response) {
              commit('setUserInfo', response.data);
              dispatch('tag/setTagKeyValues', response.data.tag_key_values, { root: true });
            }
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    /**
     * ノーログインユーザ登録処理
     * @param reqData リクエストデータ
     */
    async postRequestByNoLoginUser({ dispatch, commit }, params) {
      return new Promise((resolve, reject) => {
        http
          .post(constants.API_NOLOGINUSER_URI, params)
          .then(response => {
            if (response) {
              params = Object.assign(params, response.data);
              commit('setUserInfo', params);
              dispatch('tag/setTagKeyValues', response.data.tag_key_values, { root: true });
            }
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    /**
     * ノーログインユーザ基本情報更新処理
     * @param reqData リクエストデータ
     */
    async putBasicInfoRequestByNoLoginUser({ commit }, params) {
      return new Promise((resolve, reject) => {
        http
          .put(constants.API_NOLOGINUSER_URI + '/basicinfo/' + params.u_id, params)
          .then(response => {
            if (response) {
              params = Object.assign(params, response.data);
              commit('setUserInfo', params);
            }
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    /**
     * ユーザ情報（ID以外）の初期化
     */
    clearUserExcludeId({ commit }) {
      commit('clearUserInfo', initialDataExcludeId);
    },
    /**
     * ユーザ情報の初期化
     */
    clearUser({ commit }) {
      commit('clearUserInfo', initialData);
    }
  },
  mutations: {
    setUserInfo(state, userInfo) {
      if (isEmpty(userInfo)) {
        return;
      }
      if ('userId' in userInfo) {
        state.user_id = userInfo.userId;
      }
      if ('nickname' in userInfo) {
        state.nickname = userInfo.nickname;
      }
      if ('age' in userInfo) {
        state.age = userInfo.age;
      }
      if ('birthYear' in userInfo) {
        state.birth_year = userInfo.birthYear;
      }
      if ('gender' in userInfo) {
        state.gender = userInfo.gender;
      }
      if ('emailAddress' in userInfo) {
        state.email = userInfo.emailAddress;
      }
      if ('residence' in userInfo) {
        state.residence = userInfo.residence;
      }
      if ('isReceivingEmails' in userInfo) {
        state.is_receiving_emails = userInfo.isReceivingEmails;
      }
      if ('job' in userInfo) {
        state.job = userInfo.job;
      }
      if ('jobType' in userInfo) {
        state.job_type = userInfo.jobType;
      }
      if ('deleted' in userInfo) {
        state.deleted = userInfo.deleted;
      }
      if ('deletedAt' in userInfo) {
        state.deleted_at = userInfo.deletedAt;
      }
      if ('permission' in userInfo) {
        state.permission = userInfo.permission;
      }
      if ('tenant_id' in userInfo) {
        state.tenant_id = userInfo.tenant_id;
      }
      if ('tenant_code' in userInfo) {
        state.tenant_code = userInfo.tenant_code;
      }
      if ('age_range' in userInfo) {
        state.age_range = userInfo.age_range;
      }
      if ('tags' in userInfo) {
        state.tags = userInfo.tags;
      }
    },
    clearUserInfo(state, initialData) {
      for (let key in initialData) {
        state[key] = initialData[key];
      }
    }
  }
};
