<template>
  <div><slot /></div>
</template>
<script>
export default {
  props: {
    className: {
      type: String,
      required: false,
      default: ''
    }
  },
  beforeUnmount() {
    document.body.className = '';
  },
  mounted() {
    document.body.className = this.className;
  }
};
</script>
