import { Auth } from 'aws-amplify';
import { trackRouter } from 'vue-gtag-next';
import { createRouter, createWebHistory } from 'vue-router';
import http from '@/common/http';
import constants from '@/config/constants';
import store from '@/store/index.js';
import Home from '../views/Home.vue';
import { useCookies } from 'vue3-cookies';
const { cookies } = useCookies();
import { isEmpty } from '@/common/validators';
import { accessLog } from '@/common/accessLog';
import { decryptionTag } from '@/common/cryptionTags';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { requiresAuth: false }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/user/Login.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/registPlanConfirm/:tags',
    name: 'RegistPlanConfirm',
    component: () => import(/* webpackChunkName: "login" */ '../views/user/RegistPlanConfirm.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import(/* webpackChunkName: "logout" */ '../views/user/Logout.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/resetPasswordVerify',
    name: 'ResetPasswordVerify',
    component: () => import(/* webpackChunkName: "resetPasswordVerify" */ '../views/user/ResetPasswordVerify.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/resetPassword',
    name: 'ResetPassword',
    component: () => import(/* webpackChunkName: "resetPassword" */ '../views/user/ResetPassword.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/info',
    name: 'Info',
    component: () => import(/* webpackChunkName: "Info" */ '../views/Info.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/chat',
    name: 'Chat',
    component: () => import(/* webpackChunkName: "chat" */ '../views/Chat.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/eqChat',
    name: 'EqChat',
    component: () => import(/* webpackChunkName: "eqChat" */ '../views/EqChat.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/chatHistoryList',
    name: 'ChatHistoryList',
    component: () => import(/* webpackChunkName: "chatHistoryList" */ '../views/chat_history/ChatHistoryList.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/chatHistory',
    name: 'ChatHistory',
    component: () => import(/* webpackChunkName: "chatHistory" */ '../views/chat_history/ChatHistory.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/userRegister',
    name: 'UserRegister',
    redirect: '/userRegister/agreeTerms',
    component: () => import(/* webpackChunkName: "userRegister" */ '../views/user/UserRegister.vue'),
    meta: { requiresAuth: false },
    children: [
      {
        path: 'agreeTerms',
        name: 'AgreeTerms',
        component: () => import(/* webpackChunkName: "agreeTerms" */ '../views/AgreeTerms.vue'),
        meta: { requiresAuth: false }
      },
      {
        path: 'agreePrivacyPolicy',
        name: 'AgreePrivacyPolicy',
        component: () => import(/* webpackChunkName: "agreePrivacyPolicy" */ '../views/AgreePrivacyPolicy.vue'),
        meta: { requiresAuth: false }
      },
      {
        path: 'userInfo',
        name: 'UserInfo',
        component: () => import(/* webpackChunkName: "userInfo" */ '../views/user/UserInfo.vue'),
        meta: { requiresAuth: false }
      },
      {
        path: 'userInfoConfirmation',
        name: 'UserInfoConfirmation',
        component: () => import(/* webpackChunkName: "userInfoConfirmation" */ '../views/user/UserInfoConfirmation.vue'),
        meta: { requiresAuth: false }
      },
      {
        path: 'verify',
        name: 'Verify',
        component: () => import(/* webpackChunkName: "verify" */ '../views/user/Verify.vue'),
        meta: { requiresAuth: false }
      },
      {
        path: 'planApplication',
        name: 'UserRegisterPlanApplication',
        component: () => import(/* webpackChunkName: "userRegisterPlanApplication" */ '../views/user/UserRegisterPlanApplication.vue'),
        meta: { requiresAuth: false }
      },
      {
        path: 'successSignUp',
        name: 'SuccessSignUp',
        component: () => import(/* webpackChunkName: "successSignUp" */ '../views/user/SuccessSignUp.vue'),
        meta: { requiresAuth: false }
      }
    ]
  },
  {
    path: '/userReference',
    name: 'UserReference',
    component: () => import(/* webpackChunkName: "userReference" */ '../views/user/UserReference.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/userMaintenance',
    name: 'UserMaintenance',
    component: () => import(/* webpackChunkName: "userMaintenance" */ '../views/user/UserMaintenance.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/updatePassword',
    name: 'UpdatePassword',
    component: () => import(/* webpackChunkName: "updatePassword" */ '../views/user/UpdatePassword.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/updateMailAddress',
    name: 'UpdateMailAddress',
    component: () => import(/* webpackChunkName: "updateMailAddress" */ '../views/user/UpdateMailAddress.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/verifyByUpdate',
    name: 'VerifyByUpdate',
    component: () => import(/* webpackChunkName: "verifyByUpdate" */ '../views/user/VerifyByUpdate.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/columnList',
    name: 'ColumnList',
    component: () => import(/* webpackChunkName: "columnList" */ '../views/ColumnList.vue'),
    meta: { requiresAuth: false },
    children: [
      {
        path: '/column/:columnId',
        name: 'Column',
        component: () => import(/* webpackChunkName: "column" */ '../views/Column.vue'),
        meta: { requiresAuth: false }
      }
    ]
  },
  {
    path: '/deleteAccountConfirm',
    name: 'DeleteAccountConfirm',
    component: () => import(/* webpackChunkName: "deleteAccountConfirm" */ '../views/user/DeleteAccountConfirm.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/deleteAccountSuccess',
    name: 'DeleteAccountSuccess',
    component: () => import(/* webpackChunkName: "deleteAccountSuccess" */ '../views/user/DeleteAccountSuccess.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/serviceStatus',
    name: 'ServiceStatus',
    component: () => import(/* webpackChunkName: "serviceStatus" */ '../views/user/ServiceStatus.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/error',
    name: 'Error',
    component: () => import(/* webpackChunkName: "error" */ '../views/Error.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/cocoro',
    name: 'Cocoro',
    component: () => import(/* webpackChunkName: "cocoro" */ '../views/Cocoro.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/howto',
    name: 'HowTo',
    component: () => import(/* webpackChunkName: "howto" */ '../views/HowTo.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import(/* webpackChunkName: "terms" */ '../views/Terms.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/privacyPolicy',
    name: 'PrivacyPolicy',
    component: () => import(/* webpackChunkName: "privacyPolicy" */ '../views/PrivacyPolicy.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/planApplication',
    name: 'PlanApplication',
    component: () => import(/* webpackChunkName: "planApplication" */ '../views/user/PlanApplication.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/unregistPlanConfirm',
    name: 'UnregistPlanConfirm',
    component: () => import(/* webpackChunkName: "unregistPlanConfirm" */ '../views/user/UnregistPlanConfirm.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/unregistPlanSuccess',
    name: 'UnregistPlanSuccess',
    component: () => import(/* webpackChunkName: "unregistPlanSuccess" */ '../views/user/UnregistPlanSuccess.vue'),
    meta: { requiresAuth: false }
  },

  {
    path: '/consultation',
    name: 'Consultation',
    component: () => import(/* webpackChunkName: "consultation" */ '../views/Consultation.vue'),
    meta: { requiresAuth: false }
  },

  {
    path: '/links',
    name: 'Links',
    component: () => import(/* webpackChunkName: "links" */ '../views/Links.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/reagreeTerms',
    name: 'ReagreeTerms',
    component: () => import(/* webpackChunkName: "reagreeTerms" */ '../views/ReagreeTerms.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/reagreePrivacyPolicy',
    name: 'ReagreePrivacyPolicy',
    component: () => import(/* webpackChunkName: "reagreePrivacyPolicy" */ '../views/ReagreePrivacyPolicy.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/registTenantForm',
    name: 'RegistTenantForm',
    component: () => import(/* webpackChunkName: "registTenantForm" */ '../views/user/RegistTenantForm.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/positiveeq',
    name: 'Positiveeq',
    redirect: '/positiveeq/home',
    component: () => import(/* webpackChunkName: "Positiveeq" */ '../views/positiveeq/Positiveeq.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        path: 'home',
        name: 'PositiveeqHome',
        component: () => import(/* webpackChunkName: "PositiveeqHome" */ '../views/positiveeq/PositiveHome.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'daily',
        name: 'PositiveeqDailyRegister',
        redirect: '/positiveeq/daily/howto',
        component: () => import(/* webpackChunkName: "PositiveeqDailyRegister" */ '../views/positiveeq/PositiveeqDailyRegister.vue'),
        meta: { requiresAuth: true },
        children: [
          {
            path: 'howto',
            name: 'PositiveeqDailyHowto',
            component: () => import(/* webpackChunkName: "PositiveeqDailyHowto" */ '../views/positiveeq/PositiveeqDailyHowto.vue'),
            meta: { requiresAuth: true }
          },
          {
            path: 'score',
            name: 'PositiveeqDailyScore',
            component: () => import(/* webpackChunkName: "PositiveeqDailyScore" */ '../views/positiveeq/PositiveeqDailyScore.vue'),
            meta: { requiresAuth: true }
          },
          {
            path: 'goodThings',
            name: 'PositiveeqDailyGoodThings',
            component: () => import(/* webpackChunkName: "PositiveeqDailyGoodThings" */ '../views/positiveeq/PositiveeqDailyGoodThings.vue'),
            meta: { requiresAuth: true }
          },
          {
            path: 'complete',
            name: 'PositiveeqDailyComplete',
            component: () => import(/* webpackChunkName: "PositiveeqDailyComplete" */ '../views/positiveeq/PositiveeqDailyComplete.vue'),
            meta: { requiresAuth: true }
          }
        ]
      },
    ]
  },
  { path: '/:pathMatch(.*)', redirect: '/' }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(async to => {
  try {
    let isLogin = false;
    // ストアのユーザ情報の更新フラグ
    let isUserReload = true;
    // 暗号化タグ情報
    let encryptionTags = null;
    if (!isEmpty(to.query.tags)) {
      // Getパラメータにタグ情報が存在する場合
      // vueの仕様でtagsの値がデコードされた値になるため再度エンコードを行う
      encryptionTags = encodeURIComponent(to.query.tags);
    }
    // 復号化タグ情報
    let decryptionTags = null;
    // リダイレクト先がある場合ページ情報を設定する
    let redirect = null;
    // 利用規約再同意フラグ
    let usersReagree = false;
    // 個人情報の取扱い再同意フラグ
    let personalReagree = false;
    // 未登録のプロフィール拡張項目登録フラグ
    let unregisteredTenantForm = false;

    // クッキーのユーザID、ストアのユーザIDを取得
    let cUid = cookies.get('user_id');
    let uid = store.getters['user/user_id'];

    await Auth.currentAuthenticatedUser()
      .then(() => {
        isLogin = true;
      })
      .catch(() => {
        isLogin = false;
      });

    if (!isLogin && to.meta.requiresAuth) {
      // 未ログインで認証が必要な画面を表示しようとした場合、遷移先をログイン画面に書き換える
      return { name: 'Login' };
    } else if (isLogin && uid == null) {
      // ログイン済かつストアにユーザIDが無い場合、情報の取得を行う
      await store.dispatch('user/getRequest').then(response => {
        if (response) {
          usersReagree = response.usersReagree;
          personalReagree = response.personalReagree;
          unregisteredTenantForm = response.unregisteredTenantForm;
        }
        isUserReload = false;
      });
    }

    // Getパラメータにタグ情報が存在する場合、タグを複合化する。
    if (encryptionTags) {
      decryptionTags = await decryptionTag(encryptionTags);
    }

    // ノーログインユーザ再アクセス時の処理
    if (!isLogin && !isEmpty(cUid)) {
      if (!isEmpty(uid)) {
        // CookieにユーザIDを保存
        cookies.set('user_id', uid);
      }

      // Getパラメータにタグ情報が存在する場合、パラメータに追加
      let paramTags = decryptionTags;

      // ストアにユーザIDがない、またはユーザのタグ情報を更新する場合
      if (isEmpty(uid) || !isEmpty(paramTags)) {
        let userParams = {
          u_id: cUid,
          NoLoginAccess: true
        };

        if (!isEmpty(paramTags)) {
          // タグ情報がある場合パラメータに追加
          userParams.tags = paramTags;
        }

        // ユーザ取得処理
        await store
          .dispatch('user/getRequestByNoLoginUser', userParams)
          .then(() => {
            isUserReload = false;
          })
          .catch(error => {
            // ユーザが存在しない場合
            // ユーザ情報の初期化
            cookies.remove('user_id');
            store.dispatch('user/clearUser');
            // 共通エラー処理を行う
            throw error;
          });
      }
    }
    // ノーログインユーザ初回アクセス時の処理
    else if (!isLogin && isEmpty(cUid)) {
      let userParams = {};

      // Getパラメータにタグ情報が存在する場合、パラメータに追加
      let paramTags = decryptionTags;
      if (!isEmpty(paramTags)) {
        userParams.tags = paramTags;
      }

      // ユーザ登録処理
      userParams.NoLoginAccess = true;
      await store.dispatch('user/postRequestByNoLoginUser', userParams).then(async () => {
        // CookieにユーザIDを保存
        uid = store.getters['user/user_id'];
        cookies.set('user_id', uid);
        isUserReload = false;
      });
    }

    if (isLogin && decryptionTags) {
      // ログイン状態でURLパラメータにTagsが設定されている場合、テナント自動紐づけを行う
      await http
        .get(constants.API_USER_REGIST_PLAN_URI + '/' + decryptionTags)
        .then(function() {
          // パラメーターにテナントコードを設定し、テナント自動紐づけ画面に遷移する
          redirect = { name: 'RegistPlanConfirm', params: { tags: encryptionTags } };
        })
        .catch(e => {
          if (e.cocoroMessageId != 'ERROR_REGIST_PLAN_ALREADY_EXISTS' && e.cocoroMessageId != 'ERROR_REGIST_PLAN_TENANT_NOT_FOUND') {
            // エラー内容がプランの重複とテナントが使用不可以外の場合、共通エラー処理を行う
            throw e;
          }
        });

      // リダイレクト先の設定がある場合、設定された画面に遷移する
      if (!isEmpty(redirect)) return redirect;
    }
    if (to.name === 'Home') {
      // ユーザ情報の再読み込み処理
      if (isLogin && isUserReload) {
        // ログインユーザのストアのユーザ情報を更新
        await store.dispatch('user/getRequest').then(response => {
          if (response) {
            usersReagree = response.usersReagree;
            personalReagree = response.personalReagree;
            unregisteredTenantForm = response.unregisteredTenantForm;
          }
        });
      } else if (!isLogin && isUserReload) {
        let params = {
          u_id: cUid,
          NoLoginAccess: true
        };
        // ノーログインユーザのストアのユーザ情報を更新
        await store.dispatch('user/getRequestByNoLoginUser', params);
      }

      if (isLogin) {
        if (unregisteredTenantForm === true) {
          // 未登録のプロフィール拡張項目がある場合
          // プロフィール拡張項目入力画面へ遷移
          return (redirect = { name: 'RegistTenantForm' });
        }

        if (usersReagree === true) {
          // 会員に関する規約の再同意が必要な場合
          // 会員に関する規約の再同意画面へ遷移
          return (redirect = { name: 'ReagreeTerms' });
        } else if (personalReagree === true) {
          // 個人に関する規約の再同意が必要な場合
          // 個人に関する規約の再同意画面へ遷移
          return (redirect = { name: 'ReagreePrivacyPolicy' });
        }
      }
    }
    // ユーザ利用履歴登録
    if (!constants.USER_USAGE_HISTORY_EXCLUSION_SCREEN_NAME_LIST.includes(to.name)) {
      // 対象外以外の画面に遷移する場合はユーザ利用履歴を登録する
      await postUserUsageHistory(to.name, isLogin);
    }
  } catch (error) {
    // 共通エラー処理
    return { name: 'Error' };
  }
});

/**
 * ユーザ利用履歴への登録処理
 * @param {*} screenName 画面名
 * @param {*} login ログイン有無
 */
const postUserUsageHistory = async function(screenName, login) {
  accessLog(screenName, null, null, null, 'view', null, login);
};

trackRouter(router);

export default router;
