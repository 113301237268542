<template>
  <span>
    <span v-if="isExistRule('required')" class="form_required">{{ $t('form.ruleLabel.required') }}</span>
  </span>
</template>

<script>
import { isExistRule } from '@/common/formUtils';

export default {
  props: {
    rules: {
      type: String,
      default: ''
    }
  },
  computed: {
    isExistRule: function() {
      const _this = this;
      return function(rule) {
        return isExistRule(_this.rules, rule);
      };
    }
  }
};
</script>
