import * as yup from 'yup';

import { getMessage } from '@/common/messages';
import { isEmpty, isMaxLength, isMinLength } from '@/common/validators';
import constants from '@/config/constants';

/**
 * フォーム用バリデーション
 * @param {*} type タイプ
 * @param {*} value 値
 * @param {*} strRules ルール文字列（複数の場合は"|"区切り）
 * @param {*} label ラベル
 * @param {*} compValue 比較用値
 * @param {*} compLabel 比較用ラベル
 * @return エラー時はエラーメッセージ
 */
export function formValidate(type, value, strRules, label, compValue, compLabel) {
  if (isEmpty(strRules)) return true;

  let rules = strRules.split('|');
  for (let rule of rules) {
    if (rule === 'required') {
      if (isEmpty(value)) {
        if (type === 'text' || type === 'password') {
          if (label) {
            return getMessage('ERROR_INPUT_REQUIRED_TARGET', [label]);
          } else {
            return getMessage('ERROR_INPUT_REQUIRED');
          }
        } else if (type === 'select' || type === 'checkbox' || type === 'radio') {
          if (label) {
            return getMessage('ERROR_SELECT_REQUIRED_TARGET', [label]);
          } else {
            return getMessage('ERROR_SELECT_REQUIRED');
          }
        }
      }
    } else if (rule.match(/max/)) {
      let maxLength = rule.split(':')[1];
      if (!isMaxLength(value, maxLength)) {
        if (label) {
          return getMessage('ERROR_INPUT_MAX_LENGTH_TARGET', [label, maxLength]);
        } else {
          return getMessage('ERROR_INPUT_MAX_LENGTH', [maxLength]);
        }
      }
    } else if (rule.match(/min/)) {
      let minLength = rule.split(':')[1];
      if (!isMinLength(value, minLength)) {
        if (label) {
          return getMessage('ERROR_INPUT_MIN_LENGTH_TARGET', [label, minLength]);
        } else {
          return getMessage('ERROR_INPUT_MIN_LENGTH', [minLength]);
        }
      }
    } else if (rule === 'email') {
      if (
        !yup
          .string()
          .email()
          .isValidSync(value)
      ) {
        if (label) {
          return getMessage('ERROR_INPUT_EMAIL_TARGET', [label]);
        } else {
          return getMessage('ERROR_INPUT_EMAIL');
        }
      }

      // ドメイン名のラベル文字数チェック
      if (!isEmpty(value)) {
        let emailAdress = value.split('@');
        let domain = emailAdress[1].split('.');

        for (const domainLabel of domain){
          if (domainLabel.length > constants.DOMAIN_LABEL_MAX_NUM) {
            if (label) {
              return getMessage('ERROR_INPUT_EMAIL_TARGET', [label]);
            } else {
              return getMessage('ERROR_INPUT_EMAIL');
            }
          }
        }
      }
    } else if (rule === 'password') {
      if (
        !yup
          .string()
          // ^ $ * . [ ] { } ( ) ? " ! @ # % & / \ , > < ' : ; | _ ~ `
          .matches(/^[a-zA-Z0-9^$*.[\]{}()?"!@#%&/\\,><':;|_~`]+$/, { excludeEmptyString: true })
          .isValidSync(value)
      ) {
        if (label) {
          return getMessage('ERROR_INPUT_PASSWORD_TARGET', [label]);
        } else {
          return getMessage('ERROR_INPUT_PASSWORD');
        }
      }
    } else if (rule.match(/confirmed/)) {
      if (value !== compValue) {
        if (label) {
          return getMessage('ERROR_INPUT_CONFIRM_TARGET', [label, compLabel]);
        } else {
          return getMessage('ERROR_INPUT_CONFIRM', [compLabel]);
        }
      }
    } else if (rule === 'space_only') {
      // 半角スペース・全角スペース・タブ文字・改行だけで構成された文字列の場合エラーメッセージを返す
      if (
        yup
          .string()
          .matches(/^\s+?$/)
          .isValidSync(value)
      ) {
        if (label) {
          return getMessage('ERROR_INPUT_SPACE_ONLY_TARGET', [label]);
        } else {
          return getMessage('ERROR_INPUT_SPACE_ONLY');
        }
      }
    }
  }
  return true;
}

/**
 * 指定したルールがルール文字列に存在するか否かを返却
 * @param {*} strRules ルール文字列（複数の場合は"|"区切り）
 * @param {*} rule ルール
 * @return 存在有無（true：存在する、false：存在しない）
 */
export function isExistRule(strRules, rule) {
  if (isEmpty(strRules) || isEmpty(rule)) return false;

  let rules = strRules.split('|');
  if (rules.includes(rule)) {
    return true;
  }
  return false;
}
