import http from '@/common/http';
import constants from '@/config/constants';

/**
 * タグの複合化
 * @param {String} tags タグ
 */
export const decryptionTag = async (tags) => {
  const params = {
    NoLoginAccess: true,
  };
  let newTags = null;

  await http
    .get(constants.API_TAGS_URI + '/decryption?tags=' + tags, params)
    .then(response => {
      if (response) {
        newTags = response.data;
      }
    })
    .catch(() => {
      // エラーは無視
    });

  return newTags;
};

/**
 * タグの暗号化
 * @param {String} tags タグ
 */
export const encryptionTag = async (tags) => {
  const params = {
    NoLoginAccess: true,
  };
  let newTags = null;

  await http
    .get(constants.API_TAGS_URI + '/encryption?tags=' + tags, params)
    .then(response => {
      if (response) {
        newTags = response.data;
      }
    })
    .catch(() => {
      // エラーは無視
    });

  return newTags;
};
