<template>
  <div :id="name" class="modal fade modal-confirm" tabindex="-1" aria-labelledby="dialog-cfm" aria-hidden="true" data-bs-backdrop="static">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h3 id="dialog-cfm" class="modal-title">{{ title }}</h3>
        </div>
        <div class="modal-body d-flex align-items-center">
          <p style="white-space:pre-wrap;">{{ contents }}</p>
        </div>
        <div class="modal-footer justify-content-evenly">
          <button type="button" class="btn btn-sm border-0 btn-outline-secondary" data-bs-dismiss="modal" @click="clickNo">
            {{ noButton }}
          </button>
          <button type="button" class="btn btn-sm border-0 btn-outline-primary px-4" data-bs-dismiss="modal" @click="clickYes">
            {{ yesButton }}
          </button>
        </div>
        <button ref="hiddenSubmit" class="none" type="submit" />
      </div>
    </div>
  </div>
</template>

<script>
import FormMixin from '@/mixins/form/formMixin';

export default {
  mixins: [FormMixin],
  props: {
    title: {
      type: String,
      default: 'Information'
    },
    contents: {
      type: String,
      required: true
    },
    yesButton: {
      type: String,
      default: 'Yes'
    },
    noButton: {
      type: String,
      default: 'No'
    },
    yesSubmit: {
      type: Boolean,
      default: false
    },
    noSubmit: {
      type: Boolean,
      default: false
    }
  },
  emits: ['yesClick', 'noClick'],
  methods: {
    clickYes: function() {
      this.$emit('yesClick');
      if (this.yesSubmit) {
        this.$refs.hiddenSubmit.click();
      }
    },
    clickNo: function() {
      this.$emit('noClick');
      if (this.noSubmit) {
        this.$refs.hiddenSubmit.click();
      }
    }
  }
};
</script>
