const initialData = {
  isClickPositiveeqLink: false,
};

export default {
  namespaced: true,
  state: {
    isClickPositiveeqLink: initialData.isClickPositiveeqLink,
  },
  getters: {
    isClickPositiveeqLink(state) {
      return state.isClickPositiveeqLink;
    },
  },
  actions: {
    enableIsClickPositiveeqLink({ commit }) {
      commit('setIsClickPositiveeqLink', true);
    },
    disableIsClickPositiveeqLink({ commit }) {
      commit('setIsClickPositiveeqLink', false);
    },
    /**
     * ポジヘル情報の初期化
     */
    clearPositiveeqInfo({ commit }) {
      commit('clearPositiveeqInfo', initialData);
    }
  },
  mutations: {
    setIsClickPositiveeqLink(state, isClickPositiveeqLink) {
      state.isClickPositiveeqLink = isClickPositiveeqLink;
    },
    clearPositiveeqInfo(state, initialData) {
      for (let key in initialData) {
        state[key] = initialData[key];
      }
    }
  }
};
