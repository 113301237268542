<template>
  <div id="waiting1" class="wait"></div>
</template>

<script>
import $ from 'jquery';

export default {
  props: {
    inProgress: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    inProgress: function(newVal) {
      // タイプ1
      const tar = '#waiting1';
      if (newVal) {
        // スピナーをフェードイン
        $(tar).fadeIn(500);
      } else {
        // スピナーをフェードアウト
        $(tar).fadeOut(500);
      }
    }
  }
};
</script>
