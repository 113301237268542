import RuleLabel from '@/components/form/RuleLabel.vue';

export default {
  components: {
    RuleLabel
  },
  props: {
    name: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'text'
    },
    label: {
      type: String,
      default: ''
    },
    initValue: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    rules: {
      type: String,
      default: ''
    },
    errLabel: {
      type: String,
      default: ''
    }
  }
};
